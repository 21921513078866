// import "firebase/auth"
// import "firebase/firestore"
//import "firebase/functions"
import "@fontsource/roboto-slab"
import "@fontsource/roboto-mono"
import "@fontsource/roboto"
import "@fontsource/caveat"
import "@fontsource/bebas-neue"
//TODO VER SI SOLO IMPORTAR LAS USADAS POR CLIENTE

import React from "react"
import AuthProvider from "./src/context/auth"

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)