import React, { createContext, useState, useEffect } from "react"
import app from "gatsby-plugin-firebase-v9.0"
//import firebase from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth"
// https://dev.to/ruppysuppy/redux-vs-context-api-when-to-use-them-4k3p
export const AuthContext = createContext({})

const AuthProvider = ({ children }) => {
  const auth = getAuth()

  const [user, setUser] = useState()

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        //   const uid = user.uid;
        setUser(user)
        // ...
      } else {
        // User is signed out
        // ...
        setUser()

        //TODO el navigate("/")
      }
    })
    // app.auth().onAuthStateChanged(user => setUser(user))
  }, [])

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
